import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";

export const data = graphql`query ComplaintsQuery {
  file(relativePath: {eq: "nemo-website-figures-h2-2019.pdf"}) {
    publicURL
  }
}
`

const ComplaintsPage = ({ data }) => (
    <Layout>
        <Seo title={'How to complain'} description={'Nemo Personal Finance Complaints Procedure - if you have cause for complaint, find out about how to raise it and the procedure we go through to rectify the issue.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>How to complain</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">We always aim to provide an excellent standard of service. However, we recognise that on
                            occasion things may go wrong. Our complaints procedure is here to help you achieve a speedy
                            and satisfactory resolution.</p>
                        </div>
                    </div>
                    <h6>Making a complaint</h6>
                    <p>You can make a complaint:</p>
                    <ul>
                        <li>By phone: 0800 612 9982</li>
                        <li>By email: <a href="mailto:complaints@nemoloans.co.uk ">complaints@nemoloans.co.uk </a></li>
                        <li>In writing: "FREEPOST PRINCIPALITY BUILDING SOCIETY - Nemo Complaints" (No
                            stamp needed)</li>
                    </ul>
                    <h6>How a complaint will be handled</h6>
                    <p>You'll receive a letter formally acknowledging your complaint. This will be sent within 10 days.</p>
                    <p>We always aim to investigate complaints as quickly as possible, but if your complaint is still
                    open after 4 weeks, we'll write to you with an update.</p>
                    <p>If your complaint is still open after 8 weeks we’ll write again.</p>
                    <h6>Our final decision</h6>
                    <p>Our Complaints Department may try and phone you but we'll always provide a response in
                    writing or by email. This will let you know our decision related to your complaint, along with our
                    reasons for reaching our decision.</p>
                    <p>An email and telephone number will also be provided in every letter for you to contact us should
                    you wish to discuss this further.</p>
                    <h6>Financial Ombudsman Service</h6>
                    <p>If you are dissatisfied with our final response, you may be able to refer the matter to the
                    Financial Ombudsman Service for review. If this is the case, we'll inform you in writing.</p>
                    <p>If you wish to refer a complaint to the Financial Ombudsman, you must do so within 6
                    months from the date of our final response.</p>
                    <ul>
                        <li>Postal address: The Financial Ombudsman Service, Exchange Tower, London E14 9SR</li>
                        <li>Telephone number: 0300 123 9 123</li>
                        <li>Switchboard: 020 7964 1000</li>
                        <li>From outside the UK: +442079640500</li>
                        <li>Email: <a href="mailto:complaint.info@financial-ombudsman.org.uk">complaint.info@financial-ombudsman.org.uk</a></li>
                        <li>Website address: <a href="http://www.financialombudsman.org.uk/consumer/complaints.htm">http://www.financialombudsman.org.uk/consumer/complaints.htm</a></li>
                    </ul>
                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default ComplaintsPage;